import 'leaflet/dist/leaflet.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import L from 'leaflet'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import Fade from 'react-reveal/Fade'

export interface Props {
  description?: string
  googleMapsURL?: string
  image?: ImageProps
  languageCode: string
  latitude?: string
  longitude?: string
  siteName?: string
  title?: string
  zoom?: string
}

export const Map = memo(function Map({
  description,
  googleMapsURL,
  image,
  languageCode,
  latitude,
  longitude,
  siteName,
  title,
  zoom,
}: Props) {
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [38, 48],
        iconAnchor: [19, 48],
        html: '<svg xmlns="http://www.w3.org/2000/svg" width="37.586" height="48" viewBox="0 0 37.586 48"><path d="M24.793,1C14.457,1,6,9.144,6,19.167A17.787,17.787,0,0,0,9.759,30.025l14.2,18.584a1.123,1.123,0,0,0,1.462.209l.209-.209,14.2-18.584a17.787,17.787,0,0,0,3.759-10.858C43.586,9.144,35.129,1,24.793,1ZM23.331,26.266A7.289,7.289,0,1,1,31.893,17.7a7.358,7.358,0,0,1-5.742,8.561A6.421,6.421,0,0,1,23.331,26.266Z" transform="translate(-6 -1)" /></svg>',
      }),
    )
  }, [])

  return (
    <Container row wrap stretch tag="section">
      <LeftSide>
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
        <Fade left distance="7.5rem">
          <Wrapper>
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {googleMapsURL ? (
              <CTA
                label={useVocabularyData('open-map', languageCode)}
                URL={googleMapsURL}
                rel="noreferrer"
                target="_blank"
              />
            ) : null}
          </Wrapper>
        </Fade>
      </LeftSide>
      <RightSide>
        {typeof window !== undefined && latitude && longitude ? (
          <MapContainer
            center={[Number(latitude), Number(longitude)]}
            dragging={L.Browser && L.Browser.mobile ? false : true}
            zoom={Number(zoom)}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
            <Marker
              icon={markerIcon}
              position={[Number(latitude), Number(longitude)]}
            >
              {siteName ? <Popup>{siteName}</Popup> : null}
            </Marker>
          </MapContainer>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;
  padding: 4.5rem 4.5rem 4.5rem 0;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 3.75rem 3.75rem 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
    img {
      display: none;
    }
    .react-reveal {
      animation: none !important;
    }
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 3.875rem 8.819vw 3.875rem 10vw;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 3rem 3.75rem 3rem 8.454vw;
  }

  @media (max-width: 1023px) {
    padding: 1.875rem 8.454vw;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.625rem;
`

const CTA = styled(Button)``

const RightSide = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 50%;
  }

  @media (max-width: 767px) {
    padding-bottom: 75%;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .leaflet-marker-icon {
    background: none;
    border: 0;
    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralDark1};
    }
  }
  .leaflet-left {
    top: 4.5rem;
    left: 4.5rem;

    @media (max-width: 1023px) {
      display: none;
    }
    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${rgba(theme.colors.variants.neutralLight2, 0.3)};
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-weight: 200;
        font-size: 2.375rem;
        border: 0;
        border-radius: 0 !important;
        transition: 0.3s ease-out;
        &.leaflet-control-zoom-in {
          order: 2;
        }
        &.leaflet-disabled {
          pointer-events: none;
        }
        &:hover {
          background: ${({ theme }) => theme.colors.variants.neutralLight2};
        }
      }
    }
  }
  .leaflet-bottom {
    display: none;
  }
  .leaflet-popup {
    .leaflet-popup-content-wrapper {
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      box-shadow: none;
      border-radius: 0;
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1.375rem;
      padding: 1rem 2.25rem 1.25rem;

      @media (max-width: 1023px) {
        font-size: 1rem;
      }
    }
    .leaflet-popup-content {
      line-height: 1.5rem;
      margin: 0;
    }
    .leaflet-popup-tip {
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      box-shadow: none;
    }
    .leaflet-popup-close-button {
      display: none;
    }
  }
`
